.table-primary{
  border-radius: 10px;
  overflow: hidden;
  border-color: $colorWhiteRock;
  thead{
    tr{
      th{
        font-family: $content-font-family;
        font-weight: normal;
        font-size: 22px;
        line-height: 26px;
        color: $colorJudgeGrey;
        background-color: $colorWhiteRock;
        height: 60px;
        vertical-align: middle;
      }
    }
  }
  tbody{
    tr{
      td{
        font-family: $content-font-family;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        color: $colorJudgeGrey;
        background-color: $colorSpringWood;
        height: 60px;
        vertical-align: middle;
      }
    }
  }
}

// .table > :not(caption) > * > *{
//     background-color: $colorWhiteRock;
// }