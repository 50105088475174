.location-card{
  border: 1px solid $colorSail;
  border-radius: 14px;
  overflow: hidden;
  .card-image{
    img{
      max-width: 100%;
      @include mobile-lg{
        width: 100%;
      }
    }
  }
  .card-content{
    padding: 15px;
    h4{
      font-family: $content-font-family;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.3;
      color: $colorJudgeGrey;
      margin-bottom: 10px;
    }
  }
}