
.hamburger-wrapper{
  border: 1px solid $colorBlack;
  margin-left: 10px;
  padding: 5px;
  height: 40px;
  width: 45px;
}
.hamburger{
  position: relative;
  top: 6px;
  &.hamburger--collapse{
    .hamburger-inner{
      &:before{
        width: 15px;
        top: -7px;
      }
      &:after{
        top: -14px;
      }
    }
    &.is-active{
      // margin-top: 9px;
      .hamburger-inner{
        &:before, &:after{
          width: 33px;
          top: 0;
          background-color: $colorBlack;
        }
        background-color: $colorBlack;
      }
    }
  }  
  .hamburger-inner{
    background-color: $colorBlack;
    &:before, &:after{
      background-color: $colorBlack;
    }
  }
}
