.wayanad-link{
    position: relative;
    a {
        position: absolute;
        right: 0;
        top: 0;
        @include tablet-sm {
            position: relative;
        }
        img{
            max-width: 170px;
        }
    }
}