.facilities-section{
  .facilities{
    .col-md-12{
      @include tablet-sm{
        width: 50%;
        display: flex;
      }
      @include mobile-lg{
        width: 100%;
      }
      &:nth-child(1n + 3){
        @include tablet-sm{
          margin-top: 15px;
        }
      }
      &:nth-child(1n + 2){
        @include mobile-lg{
          margin-top: 15px;
        }
      }
    }
    .col-md-12{
        &:nth-child(even){
        .plain-image-content{
          flex-direction:row-reverse;
          .image-holder{
            img{
              border-radius: 0 0 50px 0;
              @include tablet-sm{
                border-radius: 30px 30px 0 0;
              }
            }
          }
        }
      }
    }
  }
}