.testimonial{
  @include flex-left-center;
  padding: 120px;
  border-radius: 0 100px 0 100px;
  background-image: url('../../assets/img/bg/texture.jpg');
  background-repeat: repeat;
  position: relative;
  @include desktop-lg{
    padding: 80px;
  }
  @include tablet-sm{
    padding: 30px;
    border-radius: 0 30px 0 30px;
  }
  @include mobile-lg{
    padding: 30px 15px;
    border-radius: 30px;
    background-repeat: repeat;
  }
  @mixin psudo{
    line-height: 1;
    font-size: 50px;
    position: absolute;
    font-family: 'Font Awesome 6 Pro';
    font-weight: 900;

  }
  &:before{
    @include psudo;
    content: "\f10d";
    left: 250px;
    top: -25px;
    @include tablet-sm{
      left: 50px;
    }
  }
  &:after{
    content: "\f10e";
    @include psudo;
    right: 150px;
    bottom: -25px;
    @include tablet-sm{
      right: 50px;
    }
  }
  .testimonial-content{
    @include flex-left-center;
    @include mobile-lg{
      display: block;
    }
    .image-caption{
      width: 115px;
      @include mobile-lg{
        margin: auto;
      }
      .avatar{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 5px solid $colorWhite;
        overflow: hidden;
        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
        margin-bottom: 22px;
        @include tablet-sm{
          margin-bottom: 15px;
          border: 3px solid $colorWhite;
        }
        img{
          width: 100%;
        }
      }
      h6{
        font-size: 16px;
        line-height: 1.2;
        color: $colorTitle;
        text-align: center;
      }
    }
    .description{
      width: calc(100% - 206px);
      padding-left: 90px;
      margin-left: 90px;
      border-left: 1px solid $colorJudgeGrey;
      @include tablet-sm{
        padding-left: 15px;
        margin-left: 15px;
      }
      @include mobile-lg{
        width: 100%;
        padding-left: 0;
        margin-left: 0;
        padding-top: 15px;
        margin-top: 15px;
        border-left: 0;
        border-top: 1px solid $colorJudgeGrey;
      }
      p{
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.1em;
        line-height: 28px;
        color: $colorAbbey;
      }
    }
  }
}



