.contact-area{
  address{
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .e-contact{
    li{
      & + li{
        margin-top: 12px;
      }
      a{
        font-size: 18px;
        line-height: 1.2; 
        color: $colorJudgeGrey;
        i{
          color: $colorGoldDrop;
          margin-right: 10px;
        }
      }
    }
  }
  .contact-form{
    margin-top: 20px;
  }
}
 .location-map{
  height: 100%;
  width: 100%;
  @include mobile-lg{
    margin-top: 30px;
  }
  iframe{
    height: 100%;
    width: 100%;
  }
 }