.gallery-card{
  border: 1px solid #fff;
  display: block;
  position: relative;
  height: 300px;
  overflow: hidden;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include transition;
  }
  i{
    color: $colorWhite;
    font-size: 30px;
    line-height: 1;
  }
  .overlay{
    background-color: rgba($colorBlack, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    @include flex-center;
    width: 100%;
    height: 100%;
    @include transition;
    @include hide;
  }
  &:hover{
    img{
      transform: scale(1.1)
    }
    .overlay{
      @include show;
    }
  }
  &.plain{
    background-color: $colorJudgeGrey;
    @include flex-center;
    flex-flow: column;
    i{
      margin-bottom: 12px;
      color: $colorGoldDrop;
    }
    h6{
      font-size: 12px;
      line-height: 1;
      color: $colorWhite;
      font-weight: normal;
      @include transition;
    }
    &:hover{
      h6{
        color: $colorGoldDrop;
      }
    }
  }
}


