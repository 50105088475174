.room-details{
  .img-wrap{
    margin-bottom: 20px;
    height: 392px;
    img{
      border-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  h4{
    font-family: $content-font-family;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    color: $colorJudgeGrey;
    padding-bottom: 15px;
    border-bottom: 1px solid $colorGrey;
    margin-bottom: 15px;
  }
}