.form-label{
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 6px;
}
.form-group{
  margin-bottom: 12px;
}
textarea.form-control{
  height: 120px;
}