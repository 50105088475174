.badge{
  display: inline-block;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  color: $colorBlack;
  padding: 12px;
  border-radius: 5px;
  background-color: $colorWhite;
  box-shadow: 0px 3px 6px rgba($colorBlack, 0.16);
  i{
    margin-right: 10px;
    color: $colorGoldDrop;
  }
  & + .badge{
    margin-left: 4px;
  }
}

.badge-wrap{
  margin-bottom: 30px;
}