.about-us-section{
  .row{
    .col-md-4{
      &:nth-child(1){
        @include mobile-lg{
          margin-top: 30px;
        }  
      }
      &:nth-child(even){
        margin-top: 40px;
        @include mobile-lg{
          margin-top: 0;
        }
      }
      @include mobile-lg{
        margin-top: 0;
      }
    }
  }
  .plain-image{
    max-width: fit-content;
    margin: auto;
    border-radius: 30px;
    overflow: hidden;
  }
}
.rooms-section{
  position: relative;
  margin-top: 80px;
  .vector-tea-leaf{
    background-image: url('../../assets/img/icons/tea-leaf.svg');
    width: 300px;
    height: 300px;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: -140px;
    z-index: 9;
    @include tablet-sm{
      background-size: contain;
      width: 200px;
      height: 200px;
      top: -100px;
    }
  }
  &:after{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background-image: url('../../assets/img/bg/texture.jpg');
    background-repeat: repeat;
    height: 65%;
    width: 100%;
    
  }
  .container{
    position: relative;
    z-index: 9;
  }
}
.resort-facilities{
  position: relative;
  .container{
    & > .row{
      @include mobile-lg{
        flex-direction: column-reverse;
      }
    }
  }
  .row{
    .col-md-6{
      &:first-child{
        position: absolute;
        left: 0;
        width: 50%;
        padding: 0;
        @include tablet-sm{
          width: 100%;  
          position: unset;
        }
      }
      &:nth-child(2){
        margin-left: auto;
        @include tablet-sm{
          width: 100%;  
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }
      .row{
        .col-md-4{
          &:nth-child(3){
            .tile-widget{
              @include mobile-lg{
                background-color: $colorWhite;
                i{
                  color: $colorGoldDrop;
                }
                h4{
                  color: $colorTitle;
                }
              }
            }
          }
          &:nth-child(4){
            .tile-widget{
              @include mobile-lg{
                background-color: $colorJudgeGrey;
                i{
                  color: $colorSupernova;
                }
                h4{
                  color: $colorWhite;
                }
              }
            }
          }
        }
      }
    }
  }
  .row{
    margin: 0;
    height: 100%;
    .col-md-4{
      padding: 0;
      height: 265px;
      @include tablet-lg{
        height: 245px;
      }
      @include tablet-sm{
        height: 200px;
      }
      @include  mobile-lg{
        width: 50%;
      }
      &:nth-child(even){
        .tile-widget{
          background-color: $colorWhite;
          h4{
            color: $colorTitle;
          }
        }
      }
      // @include mobile-lg{
      //   &:nth-child(odd){
      //     .tile-widget{
      //       background-color: $colorWhite;
      //       h4{
      //         color: $colorTitle;
      //       }
      //     }
      //   }
      // }
    }
  }
  .content{
    padding: 148px 30px;
    border: 1px solid $colorJudgeGrey;
    border-radius: 0 100px 0 0;
    @include tablet-lg{
      padding: 80px 30px;
    }
    @include tablet-sm{
      padding: 30px;
      border-radius: 0;
    }
    @include mobile-lg{
      padding: 15px;
    }
  }
}
.gallery-section{
  .container{
    position: relative;
    .vector-binocular{
      background-image: url('../../assets/img/icons/binocular.svg');
      width: 300px;
      height: 300px;
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      top: -55px;
      z-index: 9;
      @include tablet-sm{
        background-size: contain;
        width: 200px;
        height: 200px;
        top: -30px;
      }
    }
  }
}
.slider-three-d{
  // height: 450px;
}