@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@mixin flex-left-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@mixin translateY-30 {
  transition: all 0.4s ease-in-out;
  transform:translateY(30px)
}
@mixin translateY-0 {
  transition: all 0.4s ease-in-out;
  transform:translateY(0)
}

@mixin hide {
  opacity: 0;
  visibility: hidden;
}
@mixin show {
  opacity: 1;
  visibility: visible;
}

.share-option{
  position: fixed;
  bottom: 153px;
  right: -1px;
  z-index: 999;
  margin: 0;
  @media(max-width: 767px){
    bottom: 208px;
  }
  .a2a_floating_style{
    position: relative !important;
    top: -36px;
    padding: 0;
    border: none;
    @include hide;
    @include translateY-30;
  }
  ul{
    border: 1px solid $colorSpringWood;
    border-right: 0;
    padding-bottom: 2px;
  }
}

.a2a_vertical_style {
  left: 0;
  top: 200px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border: 1px solid $colorBlack;
  border-left: none;
  // z-index: 10 !important;
  background: $colorWhite;
}
.a2a_floating_style{
  border-radius: 0 !important;
}
.share-option{
  &.active{
    .a2a_floating_style{
      @include show;
      @include translateY-0;
    }
  }
}



.back-to-top{
  background-color: $colorGoldDrop;
  color: $colorWhite;
  padding: 8px 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  line-height: 16px;
  border-radius: 4px 0 0 4px;
  @include flex-center;
  border: 1px solid $colorGoldDrop;
}
.whatsapp-backto-top{
  position: fixed;
  right: 0;
  bottom: 65px;
  margin: 0;
  flex-flow: column;
  align-items: center;
  display: flex;
  z-index: 999;
  @media(max-width: 767px){
    bottom: 120px;
  }
  .back-to-top{
    margin-top: 4px;
  }
}
.share-btn{
  padding: 8px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  border-radius: 4px 0 0 4px;
  border: 1px solid $colorGoldDrop;
  background-color: $colorGoldDrop;
  color: $colorWhite;
  position: absolute;
  right: 1px;
  bottom: 0px;
  z-index: 9999999999999999999;
}
.btn-whatsapp{
  width: 40px;
  height: 40px;
  border: 1px solid $colorGreen;;
  background-color: $colorGreen;;
  color: $colorWhite;
  @include flex-center;
  border-radius: 4px 0 0 4px;
  font-size: 22px;
  &:hover{
    color: $colorGoldDrop; 
  }
}

.click-to-connect{
  background-color: $colorGoldDrop;
  position: fixed;
  z-index: 9;
  bottom: 0;
  width: 100%;
  display: none;
  @media(max-width: 767px){
    // @include flex-left-center;
    display: flex;
  }
  li{
    width: 50%;
    & + li{
      border-left: 1px solid $colorWhite;
    }
    a{
      color: $colorWhite;
      padding:10px;
      display: block;
      text-align: center;
      i{
        margin-right: 8px;
      }
    }
  }
}


body{
  margin: 0;
  ul{
    padding: 0;
    list-style: none;
    margin: 0;
  }
}