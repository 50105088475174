.our-travel-agencies{
  max-width: 100%;
  margin: auto;
  @include flex-center;
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid $colorJudgeGrey;
  @include mobile-lg{
    justify-content: center;
  }
  .travel-card{
    min-width: 156px;
    padding: 12px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    height: 60px;
    background-color: $colorWhite;
    @include flex-center;
    @include mobile-lg{
      margin: 5px;
    }
    img{
      max-width: 100%;
    }
    & + .travel-card{
      margin-left: 15px;
      @include mobile-lg{
        margin-left: 5px;
      }
    }
  }
}


