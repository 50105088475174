.tile-widget{
  width: 100%;
  height: 100%;
  background-color: $colorJudgeGrey;
  padding: 30px;
  text-align: center;
  @include flex-center;
  flex-flow: column;
  @include mobile-lg{
    padding: 15px;
  }
  i{
    margin-bottom: 25px;
    font-size: 44px;
    line-height: 1;
    &.green{
      color: $colorRioGrande;
    }
    &.yellow{
      color: $colorSupernova;
    }
    &.blue{
      color: $colorSail;
    }
    &.black{
      color: $colorBlack;
    }
    &.orange{
      color: $colorGoldDrop;
    }
    &.light-brown{
      color: $colorTacha;
    }
  }
  h4{
    font-family: $content-font-family;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: $colorWhite;
  }
}