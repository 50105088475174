footer{
  background-image: url('../../assets/img/bg/footer-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  padding-top: 140px;
  @include tablet-sm{
    padding-top: 50px;  
  }
  @include mobile-lg{
    margin-bottom: 40px;
  }
  .brand{
    display: block;
    margin-bottom: 15px;
    img{
      max-width: 100%;
    }
  }
  address{
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $colorTitle;
    margin-bottom: 15px;
  }
  .e-contact{
    li{
      & + li{
        margin-top: 6px;
      }
      i{
        color: $colorGoldDrop;
        font-size: 14px;
        line-height: 1;
        margin-right: 5px;
      }
      a{
        font-size: 14px;
        line-height: 1;
        i{
          color: $colorGoldDrop;
        }
      }
    }
  }
  .foot-item{
    h3{
      font-family: $content-font-family;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: $colorGoldDrop;
      margin-bottom: 12px
    }
    ul.menu{
      li{
        a{
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: $colorTitle;
          &:hover{
            color: $colorGoldDrop;
          }
        }
        & + li{
          margin-top: 10px;
        }
        &.active{
          a{
            color: $colorGoldDrop;
          }
        }
      }
      &.column-2{
        column-count: 2;
      }
    }
  }
  .follow-us-on{
    @include flex-center;
    h4{
      font-family: $content-font-family;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: $colorGoldDrop;
    }
    .social-media{
      @include flex-left-center;
      li{
        margin-left: 8px;
        a{
          @include flex-center;
          width: 60px;
          height: 60px;
          font-size: 30px;
          border-radius: 50%;
          background-color: $colorJudgeGrey;
          color: $colorWhite;
          @include mobile-lg{
            width: 40px;
            height: 40px;
            font-size: 20px;
          }
        }
      }
    }
  }
  .copy-rights{
    background-color: $colorJudgeGrey;
    padding: 12px;
    margin-top: 30px;
    p{
      color: $colorWhite;
    }
  }
  .main-area{
    padding-top: 120px;
    @include tablet-sm{
      padding-top: 50px;  
    }
    .col-md-3{
      @include tablet-sm{
        width: 50%;
      }
      @include mobile-lg{
        width: 100%;
      }
      &:nth-child(1n + 3){
        @include tablet-sm{
          margin-top: 30px;
        }
      }
      &:nth-child(1n + 2){
        @include mobile-lg{
          margin-top: 20px;
        }
      }
    }
  }
  .social-media-area{
  
  }
}