.card{
  // border: none;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  .card-image{
    img{
      max-width: 100%;
      border-radius: 10px;
      @include transition;
    }
  }
  .card-title{
    margin: 0;
    bottom: 0;
    position: absolute;
    padding: 30px;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    width: 100%;
    @include tablet-sm{
      padding: 15px;
    }
    i{
      font-size: 30px;
      line-height: 1;
      margin-bottom: 10px;
      &.green{
        color: $colorRioGrande;
      }
      &.yellow{
        color: $colorSupernova;
      }
      &.blue{
        color: $colorSail;
      }
    }
    h4{
      font-weight: normal;
      font-size: 24px;
      line-height: 26px;
      color: $colorWhite;
      font-family: $content-font-family;
      @include tablet-sm{
        font-size: 22px;
        line-height: 24px;
      }
    }
  }
  &:hover{
    .card-image{
      img{
        transform: scale(1.1);
      }
    }
  }
}


.card-row{
  margin: calc(var(--bs-gutter-x) * -.5);
  & > * {
    padding: calc(var(--bs-gutter-x) * .5) ;
  }
}