.inner-banner{
  padding: 0 0 0 15px;
  @include flex-left-center;
  align-items: flex-start;
  @include tablet-sm{
    padding: 0;
  }
  .left-col{
    width: 200px;
    padding-top: 25px;
    @include tablet-sm{
      display: none;
    }
    .brand{
      width: 100%;
      display: block;
      padding-bottom: 25px;
      margin-bottom: 25px;
      position: relative;
      @include tablet-sm{
        width: auto;
        padding-bottom: 0;
        margin-bottom: 0;
      }
      &:after{
        position: absolute;
        content: "";
        width: 50px;
        height: 1px;
        background-color: $colorBlack;
        bottom: 0;
        left: 50%;
        margin-left: -25px;
        @include tablet-sm{
          display: none;
        }
      }
      img{
        max-width: 100%;
      }
    }
    .e-contact{
      font-size: 12px;
      line-height: 1;
      display: block;
      text-align: center;
      @include tablet-sm{
        display: none;
      }
      & +   .e-contact{
        margin-top: 18px;
      }
    }
  }
  .right-col{
    width: calc(100% - 200px);
    padding-left: 15px;
    position: relative;
    @include tablet-sm{
      width: 100%;
      padding-left: 0;
    }
    .banner-bg{
      height: 300px;
      overflow: hidden;
      border-radius: 0 0 0 100px;
      @include tablet-lg{
        height: 300px;
      }
      @include tablet-sm{
        border-radius: 0 0 0 70px;
      }
      img{
        width: 100%;
        min-height: 300px;
        object-fit: cover;
        border-radius: 0 0 0 100px;
        transition: 10s ease-out;
        transform: scale(1.1);
      }
    }
  }
}