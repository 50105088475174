.wavy{
  position: fixed;
  left: 0;
  top: 0;
  -webkit-box-reflect: below -12px linear-gradient(transparent,rgba($colorBlack, 0.2));
  height: 100vh;
  width: 100vw;
  @include flex-center;
  background-color: $colorWhite;
  z-index: 99999;
}
.symbol{
  position: absolute;
  top: 50%;
  margin-top: -75px;
}
.wavy span{
  font-family: 'Dancing Script', cursive;
  position: relative;
  display: inline-block;
  color: $colorBlack;
  font-size: 40px;
  letter-spacing: 2px;
  animation: animate 1s ease-in-out infinite;
  animation-delay: calc(0.1s * var(--i));
}
@keyframes animate {
  0%
  {
    transform: translateY(0px);
    color: yellow;
    opacity: 0.2;
  }
  20%
  {
    transform: translateY(-20px);
    color:$colorBlack;
  }

  40%,100%
  {
    transform: translateY(0px);
  }
}