.banner{
  padding: 0 0 0 15px;
  @include flex-left-center;
  align-items: flex-start;
  @include tablet-sm{
    padding: 0;
  }
  .left-col{
    width: 200px;
    padding-top: 50px;
    position: relative;
    z-index: 9999;
    @include tablet-sm{
      display: none;
    }
    .brand{
      width: 100%;
      display: block;
      padding-bottom: 25px;
      margin-bottom: 25px;
      position: relative;
      &:after{
        position: absolute;
        content: "";
        width: 50px;
        height: 1px;
        background-color: $colorBlack;
        bottom: 0;
        left: 50%;
        margin-left: -25px;
      }
      img{
        max-width: 100%;
      }
    }
    .e-contact{
      font-size: 12px;
      line-height: 1;
      display: block;
      text-align: center;
      & +   .e-contact{
        margin-top: 18px;
      }
    }
  }
  .right-col{
    width: calc(100% - 200px);
    padding-left: 15px;
    position: relative;
    @include tablet-sm{
      width: 100%;
      padding-left: 0;
    }
    .vector-flower{
      background-image: url('../../assets/img/icons/branch-coffee.svg');
      width: 300px;
      height: 300px;
      background-repeat: no-repeat;
      position: absolute;
      left: -200px;
      // transition: all 3s ease-in-out;
      animation: up-down_1 5s linear infinite;
      bottom: 250px;
      @include tablet-sm{
        display: none;
      }
    }
    @keyframes up-down_1{
      0% {
        bottom: 250px;
      }
      50% {
        bottom: 240px;
      }
      100% {
        bottom: 250px;
      }
    }
    .banner-bg{
      height: 100vh;
      overflow: hidden;
      border-radius: 0 0 0 100px;
      @include tablet-lg{
        height: 700px;
      }
      img{
        width: 100%;
        min-height: 100vh;
        object-fit: cover;
        border-radius: 0 0 0 100px;
        transition: 10s ease-out;
        transform: scale(1.1);
        @include tablet-lg{
          min-height: 700px;
        }
      }
    }
    .swiper-slide {
      &.swiper-slide-active{
        img{
          transform: scale(1);
        }
      }
    }
    .banner-caption{
      position: relative;
      bottom: 120px;
      left: -200px;
      margin-top: -130px;
      @include tablet-sm{
        left:0;
        margin-top: -50px;
      }
      .swiper-slide {
        .caption-wrapper{
          width: 100%;
          @include flex-left-center;
          align-items: flex-end;
          .caption{
            max-width: 580px;
            h1{
              font-size: 36px;
              line-height: 48px;
              display: inline-block;
              @include mobile-lg{
                font-size: 24px;
                line-height: 30px;
              }
              .overlay{
                padding: 8px 20px;
                overflow: hidden;
                display: inline-block;
                position: relative;
                &:after{
                  position: absolute;
                  content: "";
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  background-color: rgba($colorBlack, 0.2);
                }
                &:before{
                  position: absolute;
                  content: "";
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  background-color: $colorWhite;
                }
                & + .overlay{
                  margin-top: -12px;
                }
              }
              span{
                font-size: 36px;
                line-height: 48px;
                font-family: $base-font-family;
                display: inline-block;
                @include tablet-lg{
                  font-size: 30px;
                  line-height: 40px;
                }
                @include mobile-lg{
                  font-size: 22px;
                  line-height: 30px;
                }
              }
            }
          }
        } 
        &.swiper-slide-active{
          .caption-wrapper{
            .caption{
              h1{
                .overlay{
                  &:after{
                    animation-delay: 1s;
                    animation: revealLTR 2s cubic-bezier(.77,0,.18,1) forwards;
                    transform: translateX(0);
                  }
                  &:before{
                    animation-delay: 2s;
                    animation: revealRTL 2s cubic-bezier(.77,0,.18,1) forwards;
                    transform: translateX(0);
                  }
                }
                .text{
                  animation-delay: 3s;
                  animation: revealText 2s cubic-bezier(.77,0,.18,1) forwards;
                  transform: translateX(100px);
                }
              }
            }
          } 
        }
      }
    }
  }

}

[class^="swiper-button-"], [class*=" swiper-button-"]{
  width: 60px;
  height: 60px;
  right: 0;
  bottom: 0;
  top: auto;
  background-color: rgba($colorWhite, 0.6);
  &:after{
    font-size: 30px;
    color: $colorBlack;
  }
}
.swiper-button-prev{
  left: auto;
  right: 61px;
}

@keyframes revealLTR {
  0% {transform: translateX(-100%)}

  100% {transform: translateX(100%)}
}
@keyframes revealRTL {
  0% {transform: translateX(100%)}

  100% {transform: translateX(0)}
}
@keyframes revealText {
  0% {transform: translateX(100%)}
  80% { opacity: 1;}
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}