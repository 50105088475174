.image-card{
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  height: 185px;
  display: block;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include transition;
  }
  .overlay{
    background-color: rgba($colorBlack, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    @include flex-center;
    flex-flow: column;
    width: 100%;
    height: 100%;
    @include transition;
    @include hide;
    i{
      font-size: 26px;
      color: $colorGoldDrop;
    }
  }
  &:hover{
    img{
      transform: scale(1.1)
    }
    .overlay{
      @include show;
    }
  }
}