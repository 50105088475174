.rooms-tariff-page{
  position: relative;
  .vector-leaves{
    background-image: url('../../assets/img/icons/leaves.svg');
    width: 400px;
    height: 500px;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: -10px;
    z-index: 9;
    @include tablet-sm{
      background-size: contain;
      width: 175px;
      height: 250px;
    }
    @include mobile-lg{
      display: none;
    }
  }
}