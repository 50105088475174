html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

* {
  outline: 0;
    &,
    &:before,
    &:after {
        box-sizing: inherit;
    }
}

a, .btn{
  transition: 0.5s all ease-in-out;
  text-decoration: none;
}

body {
  background-color: $colorWhite;
  font-family: $content-font-family;
}


a{
  color: $colorBlack;
  &:hover{
    text-decoration: none;
    color: $colorJudgeGrey;
  }
}

button{
  &:focus{
    outline: none;
  }
}

ul,ol{
    margin: 0;
    padding: 0;
    list-style: none;
}

figure{
  margin: 0px;
}

address{
  margin: 0;
  padding: 0;
}
section{
  padding-top: 80px;
  @include tablet-sm{
    padding-top: 40px;
  }
}
.mandatory{
  color: $colorGoldDrop;
}
// @media (min-width: 1200px){
//   .container{
//     max-width: 1140px;
//   }
// }
// @include mobile-landscape { 
//   .container{
//     max-width: 100%;
//   }
// }

// @media (max-width: $mobile-sm){
//   .container{
//     max-width: 100%;
//     padding-left: 15px;
//     padding-right: 15px;
//   }
// }
.card-slider{
  width: 100%;
}
.mb-20{
  margin-bottom: 20px;
}
.mt-20{
  margin-top: 20px;
}
.pt-40{
  padding-top: 40px;
}