.horizontal-card{
  @include flex-left-center;
  height: 100%;
  @include tablet-sm{
    display: block;
    height: auto;
  }
  & > * {
    width: 50%;
    @include tablet-sm{
      width: 100%;
    }
  }
  .card-image{
    position: relative;
    &:after{
      position: absolute;
      content: "";
      width: 17px;
      height: 37px;
      background-color: $colorWhite;
      clip-path: polygon(0% 50%, 100% 100%, 100% 0);
      top: 50%;
      margin-top: -17px;
      right: -1px;
      @include tablet-sm{
        top: auto;
        bottom: 0;
        margin-top: 0;
        margin-right: -17px;
        margin-bottom: -1px;
        right: 50%;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        width: 37px;
        height: 17px;
      }
    }
    img{
      width: 100%;
      border-radius: 100px 0 0 0;
      @include tablet-sm{
        border-radius: 60px 0 0 0;  
      }
    }
  }
  .card-body{
    background-color: $colorSpringWood;
    height: 100%;
    border-left: 10px solid $colorWhite;
    padding: 0;
    @include tablet-sm{
      border-left: none;
      border-top: 10px solid $colorWhite;
    }
    .card-content{
      padding: 60px;
      height: 100%;
      @include tablet-lg{
        padding: 12px;
      }
      h4{
        font-family: $content-font-family;
        font-weight: normal;
        font-size: 26px;
        line-height: 1.3;
        color: $colorTitle;
        margin-bottom: 30px;
      }
      p{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
      }
    }
  }
}

.horizontal-card-row{
  & > * {
    &:nth-child(odd){
      .horizontal-card{
        flex-direction: row-reverse;
        .card-image{
          img{
            border-radius: 0 100px 0 0;
            @include tablet-sm{
              border-radius: 0 60px 0 0;
            }
          }
          &:after{
            clip-path: polygon(0 100%, 0 0, 100% 50%);
            left: 0;
            @include tablet-sm{
              top: auto;
              left: auto;
              bottom: 0;
              margin-top: 0;
              margin-right: -17px;
              margin-bottom: -1px;
              right: 50%;
              clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
              width: 37px;
              height: 17px;
            }
          }
        }
        .card-body{
          border-left: 0;
          border-right: 10px solid $colorWhite;
          right: -1px;
          @include tablet-sm{
            border-right: none;
            right: auto;
          }
        }
      }
    }
  }
}