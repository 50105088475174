// Responsive mixins
@mixin desktop-lg() {
  @media (max-width: $desktop-lg) {
    @content;
  }
}
@mixin desktop-sm() {
  @media (max-width: $desktop-sm) {
    @content;
  }
}
@mixin tablet-lg() {
  @media (max-width: $tablet-lg) {
    @content;
  }
}
@mixin tablet-sm() {
  @media (max-width: $tablet-sm) {
    @content; 
  }
}

@mixin mobile-lg() {
  @media (max-width: $mobile-lg) {
    @content;
  }
}
@mixin mobile-sm() {
  @media (max-width: $mobile-sm) {
    @content;
  }
}
@mixin mobile-landscape() {
  @media (orientation: landscape) and (max-height: 580px) and (max-width: 820px) {
    @content;
  }
}

@mixin below($value) {
  @media (max-width: $value) {
    @content;
  }
}
@mixin above($value) {
  @media (min-width: $value) {
    @content;
  }
}


@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@mixin flex-left-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@mixin flex-right-center {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

@mixin transition-translate {
  transition: all 0.4s ease-in-out;
  transform:translateY(30px)
}
@mixin transition {
  transition: all 0.4s ease-in-out;
}
@mixin text-center {
  text-align: center;
}

@mixin hide {
  opacity: 0;
  visibility: hidden;
}
@mixin show {
  opacity: 1;
  visibility: visible;
}