.pagination{
  .page-item{
    .page-link{
      color: $colorGoldDrop;
    }
    &.active{
      .page-link{
        background-color: $colorGoldDrop;
        border-color: $colorGoldDrop;
        color: $colorWhite;
      }
    }
  }
    
}