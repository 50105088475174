h6.title{
  font-family: $content-font-family;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: $colorGoldDrop;
  margin-bottom: 10px;
  text-transform: uppercase;
}
h2.title{
  font-weight: normal;
  font-size: 2.5rem;
  color: $colorJudgeGrey;
  line-height: 48px;
  margin-bottom: 20px;
  @include tablet-lg{
    font-size: 30px;
    line-height: 38px;
  }
}
h3.title{
  font-weight: normal;
  font-size: 30px;
  color: $colorJudgeGrey;
  line-height: 40px;
  margin-bottom: 12px;
  @include tablet-lg{
    font-size: 24px;
    line-height: 32px;
  }
}
.title-caption{
  max-width: 600px;
  text-align: center;
  margin: auto;
  margin-bottom: 25px;
}