.gallery-section{
  .container-fluid{
    // @include mobile-lg{
    //   padding: 0;
    // }
    .gallery{
      @include mobile-lg{
      margin: 0;
      }
      & > *{
        @include tablet-sm{
          width: 50%;
        }
        @include mobile-lg{
          width: 100%;
        }
      }
        & > * {
          padding: 0;
          &:nth-child(2){
            .gallery-card{
              height: 600px;
              @include mobile-lg{
                height: 300px;
              }
            }
          }
        }
      .gallery-row{
        margin: 0;
        & > * {
          padding: 0;
        }
      }
    }
  }
}
