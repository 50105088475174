button{
  padding: 0;
  border: none;
}
.btn{
  min-width: 140px;
  display: inline-block;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  color: $colorBlack;
  text-align: center;
  text-decoration: none; 
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 12px;
  border-radius: 0;
  transition: color .15s ease-in-out,
  background-color .15s ease-in-out,
  border-color .15s ease-in-out,
  box-shadow .15s ease-in-out;
  @include transition;
  text-transform: uppercase;
  @include below(320px){
    min-width: auto;
  }
  i{
    margin: 0 5px;
  }
  &.btn-link{
    padding: 0;
    text-align: left;
    font-size: 16px;
    @include mobile-lg{
      min-width: auto;
    }
    i{
      font-size: 12px;
      margin-left: 5px;
    }
    &:hover{
      color: $colorRed;
    }
  }
  &.btn-dark{
    border-color: $colorBlack;
    color: $colorWhite;
    background-color: $colorBlack;
    // @include btn-center;
    &:hover{
      border-color: $colorBlack;
      color: $colorBlack;
      background-color: $colorWhite;
    }
  }
  &.btn-light{
    border-color: $colorWhite;
    color: $colorBlack;
    background-color: $colorWhite;
    // @include btn-center;
    &:hover{
      border-color: $colorWhite;
      color: $colorWhite;
      background-color: $colorBlack;
    }
  }
  &.btn-out-light{
    border-color: $colorBlack;
    color: $colorBlack;
    background-color: $colorWhite;
    // @include btn-center;
    &:hover{
      border-color: $colorBlack;
      color: $colorWhite;
      background-color: $colorBlack;
    }
  }
  &.btn-danger{
    border-color: $colorRed;
    color: $colorWhite;
    background-color: $colorRed;
    // @include btn-center;
    &:hover{
      border-color: $colorRed;
      color: $colorRed;
      background-color: $colorWhite;
    }
  }
  &.btn-outline-danger{
    border-color: $colorRed;
    color: $colorRed;
  }
  &.btn-book-now{
    width: 100%;
    max-width: 310px;
    height: 50px;
    background-color: transparent;
    padding: 5px;
    position: relative;
    padding-right: 80px;
    border-color:$colorJudgeGrey;
    @include flex-center;
    @include tablet-lg{
      min-width: 200px;
    }
    @include mobile-lg{
      min-width: 100%;
    }
    .btn-group-text{
      background-color: $colorJudgeGrey;
      color: $colorWhite;
      border-color:$colorJudgeGrey;
      border-radius: 0;
      position: relative;
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;
      height: 48px;
      @include flex-center;
      &:after{
        position: absolute;
        content: "";
        width: 10px;
        height: 15px;
        background-color: $colorJudgeGrey;
        left: -9px;
        clip-path: polygon(0 50%, 100% 100%, 100% 0);
      }
    }
  }
}
