.icons-ul-list{
  column-count: 2;
  margin-bottom: 24px;
  li{
    font-family: $content-font-family;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: $colorAbbey;
    position: relative;
    padding-left: 30px;
    i{
      color: $colorGoldDrop;
      position: absolute;
      left: 0;
      top: 0;
    }
    & + li{
      margin-top: 10px;
    }
  }
}