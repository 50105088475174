/*
 * Base colors
*/

$colorWhite: #ffffff;
$colorBlack: #000000; 
$colorRed: #ff0000;
$colorTitle: #1A1A18;
$colorJudgeGrey: #584939;
$colorGoldDrop: #f08000;
$colorRioGrande: #AFCA05;
$colorGreen: #5DC066;
$colorSupernova: #FFCC00;
$colorSail: #A1D9F8;
$colorAbbey: #58595B;
$colorTacha: #D7B46A;
$colorSpringWood: #F5F1EA;
$colorWhiteRock:#EEE6D9;
$colorGrey:#E2E3E5;