// Utils
@import "util/mixins";


// Base
@import "base/variables";
// @import "base/animations";
@import "base/color";
@import "base/typography";
@import "base/base";

// Pages
@import "page/home";
@import "page/contact-us";
@import "page/facilities";
@import "page/testimonial";
@import "page/rooms-tariff-details";
@import "page/location";
@import "page/nearest-places";



// Normalise
@import "vendor/normalise";
   
// includes
@import "includes/header";
@import "includes/hero-banner";
@import "includes/gallery";
@import "includes/inner-banner";
@import "includes/plain-image-content";
@import "includes/side-widget";
@import "includes/footer";
@import "includes/popup-onload";

// Components
@import "components/button";
@import "components/title";
@import "components/breadcrumb";
@import "components/card";
@import "components/gallery-card";
@import "components/fancy-box";
@import "components/testimonial";
@import "components/our-travel-agencies";
@import "components/tile-widget";
@import "components/simple-card";
@import "components/slider-3d";
@import "components/hamburger";
@import "components/breadcrumb";
@import "components/horizontal-card";
@import "components/badge";
@import "components/image-card";
@import "components/table-primary";
@import "components/room-details";
@import "components/icons-ul-list";
@import "components/gallery-group";
@import "components/form";
@import "components/testimonial-card";
@import "components/location-card";
@import "components/preloader";
@import "components/ul-list";
@import "components/pagination";