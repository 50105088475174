.gallery-group{
  margin: -4px;
  & > * {
    padding: 4px;
    border: 1px solid $colorWhite;
    a{
      position: relative;
      display: block;
      overflow: hidden;
      border-radius: 10px;
      img{
        max-height: 288px;
        width: 100%;
        @include transition;
        object-fit: cover;
      }
      i{
        position: absolute;
        top: 50%;
        right: 50%;
        margin-left: -34px;
        margin-top: -13px;
        z-index: 9;
        font-size: 24px;
        line-height: 1;
        color: $colorWhite;
        @include transition;
      }
      .card-title{
        position: absolute;
        left: 15px;
        z-index: 1;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        top: 15px;
        text-align: center;
        @include flex-center;
        border: 1px solid $colorWhite;
        @include transition;
        padding: 15px;
      }
      &:hover{
        img{
          transform: scale(1.1);
        }
        i{
          color: $colorGoldDrop;
        }
        .card-title{
          background-color: rgba($colorWhite, 0.3);
          border-color: $colorGoldDrop;
        }
      }
    }
  }
}