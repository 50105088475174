header{
  padding: 30px 15px;
  position: fixed;
  width: 80%;
  right: 0;
  top: 0;
  z-index: 999;
  @include transition;
  @include tablet-sm{
    width: 100%;
    padding: 15px;
    right: 0;
    left: auto;
    background-color: $colorWhite;
  }
  @include mobile-lg{
    padding: 15px 0;
  }
  .main-menu{
    @include flex-left-center;
    justify-content: space-between;
    @include tablet-sm{
      display: none;
    }
    .left-col{
      @include hide;
      @include transition;
      .brand{
        max-width: 102px;
        display: block;
        img{
          max-width: 100%;
        }
      }
    }
    .right-col{
      @include flex-left-center;
      margin-left: 15px;
      .hamburger-wrapper{
        background-color: $colorBlack;
        margin-left: 15px;
        .hamburger{
          &.hamburger--collapse{
            &.is-active{
              // margin-top: 9px;
              .hamburger-inner{
                &:before, &:after{
                  background-color: $colorBlack;
                }
                background-color: $colorBlack;
              }
            }
          }  
          .hamburger-inner{
            background-color: $colorWhite;
            &:before, &:after{
              background-color: $colorWhite;
            }
          }
        }
      }
    }
    .hamburger{
      &.is-active{
        // margin-top: 9px;
        .hamburger-inner{
          &:before, &:after{
            width: 33px;
            top: 0;
            background-color: $colorBlack;
          }
          background-color: $colorBlack;
        }
      }
    }
  } 
  .mobile-menu{
    @include above(991px){
      display: none;
    }
    .col-md-4{
      width: 33.33333%;
      .brand{
        display: block;
        @include tablet-sm{
          max-width: 102px;
        }
        img{
          max-width: 100%;
        }
      }
    }
    .col-md-8{
      width: 66.66667%;
      .menu-wrap{
        @include flex-left-center;
        justify-content: flex-end;
        .btn-light{
          min-width: auto;
        }
      }
    }
  }
}
.menu-items{
  width: 50%;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  border-radius: 0 0 0 100%;
  @include transition;
  padding: 150px 100px 100px;
  @include flex-right-center;
  align-items: flex-start;
  @include hide;
  @include desktop-lg{
    padding: 70px 100px 70px;
  }
  @include tablet-lg{
    height: 50%;
    padding: 70px;
  }
  @include tablet-sm{
    width: 80%;
    height: 60%;
  }
  @include mobile-lg{
    width: 80%;
    height: auto;
    padding: 70px 30px 40px;
    justify-content: flex-start;
  }
  @include below(320px){
    padding: 70px 15px 2px;
  }
  &:after{
    box-shadow: 0 12px 24px rgba($colorBlack, 0.16);
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    right: 0;
    top: 0;
    border-radius: 0 0 0 100%;
    @include transition;
    background-color: $colorWhite;
    @include mobile-lg{
      border-radius: 0 0 0 50px;
      height: 100%;
    }
  }
  ul{
    position: relative;
    left: -100px;
    z-index: 9;
    @include transition;
    @include hide;
    @include desktop-lg{
      left: 0;
    }
    li{
      @include hide;
      & + li{
        margin-top: 15px;
        @include tablet-lg{
          margin-top: 10px;
        }
      }
      a{
        color: $colorBlack;
        font-size: 24px;
        line-height: 1.2;
        font-weight: 400;
        padding-left: 38px;
        @include desktop-lg{
          font-size: 22px;
        }
        @include tablet-lg{
          font-size: 20px;
        }
        @include below(320px){
          font-size: 16px;
        }
        i{
          margin-right: 15px;
          color: $colorGoldDrop;
          @include transition;
          position: absolute;
          left: 0;
        }
        &:hover{
          color: $colorGoldDrop;
        }
      }
      
      &.active{
        a{
          color: $colorGoldDrop;
        }
      }
    }
  }
}
body{
  &.menu-active{
    .menu-items{
      @include show;
      ul{
        @include show;
        li{
          animation-name: rotating-text-1;
          @include show;
          &:nth-child(1) {
            animation-duration: .6s;
          }
          &:nth-child(2) {
            animation-duration: .8s;
          }
          &:nth-child(3) {
            animation-duration: 1s;
          }
          &:nth-child(4) {
            animation-duration: 1.1s;
          }
          &:nth-child(5) {
            animation-duration: 1.2s;
          }  
          &:nth-child(6) {
            animation-duration: 1.3s;
          }
          &:nth-child(7) {
            animation-duration: 1.4s;
          }
          &:nth-child(8) {
            animation-duration: 1.5s;
          }  
          &:nth-child(9) {
            animation-duration: 1.6s;
          }
          &:nth-child(10) {
            animation-duration: 1.7s;
          }
          @keyframes rotating-text-1 {
            0% {
              transform: translateX(100px);
              @include hide;
            }
            100% {
              transform: translateX(0);
              @include show;
            }
          }
        }
      }
      &:after{
        width: 100%;
        height: 100vh;
        @include mobile-lg{
          height: 100%;
        }
      }
    }
  }
}

body{
  &.sticky{
    header{
      padding: 10px 30px;
      background-color: $colorWhite;
      box-shadow: 0 3px 6px rgba($colorBlack, 0.16);
      width: 100%;
      @include mobile-lg{
        padding: 15px 0;
      }
      .main-menu{
        .right-col{
          .btn-light{
            border-color: $colorBlack;
          }
        }
        .left-col{
          @include show;
        }
      }
    }
    .banner{
      .left-col{
        z-index: 0;
      }
    }
  }
}







