
/*------------------*
# Headings
*------------------*/

/*
 * Uniform spacing and color for headings
*/

html, body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $base-font-family;
  font-size: $base-font-size;
}

h1, h2, h3, h4, h5{
  font-family: $base-font-family;
}
h6{
  font-family: $content-font-family;
}
p{
  font-family: $content-font-family;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
  color: $colorAbbey;
  &:last-child{
    margin-bottom: 0;
  }
}
ul, ol, span, address{
  font-family: $content-font-family;
  font-weight: normal;
}



