.onload-modal{
    .modal-content{
        border: none;
        .modal-body{
            padding: 0;
            img{
                width: 100%;
                border-radius: 0.3rem;
            }
            .btn-close{
                position: absolute;
                top: -5px;
                right: -5px;
                width: 20px;
                height: 20px;
                @include flex-center;
                border-radius: 50%;
                opacity: 1;
                background: $colorGoldDrop url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
            }
        }
    }
}