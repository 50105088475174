.simple-card{
  border-radius: 10px;
  position: relative;
  max-width: fit-content;
  .card-image{
    img{
      max-width: 100%;
      border-radius: 10px;
    }
  }
  .card-content{
    padding: 30px;
    padding-bottom: 60px;
    background: linear-gradient(rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 0.6) 100%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include flex-center;
    align-items: flex-end;
    border-radius: 10px;
    @include mobile-lg{
      padding: 15px;
    }
    h4{
      font-family: $content-font-family;
      font-weight: normal;
      font-size: 24px;
      letter-spacing: 0.1em;
      color: $colorWhite;
      padding-bottom: 15px;
      position: relative;
      transition: all 0.7s ease-in-out;
      @include hide;
      transform: translateY(-10px);
      @include mobile-lg{
        font-size: 20px;
      }
      &:after{
        position: absolute;
        content: "";
        width: 100px;
        height: 1px;
        background-color: $colorTacha;
        bottom: 0;
        left: 50%;
        margin-left: -50px;
      }
    }
  }
}

