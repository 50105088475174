nav[aria-label="breadcrumb"]{
  padding: 30px 0 0;
  position: relative;
  &:after{
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: $colorGrey;
    top: 50%;
    margin-top: 15px;
  }
  .breadcrumb{
    @include flex-left-center;
    display: inline-flex;
    color: $colorRioGrande;
    font-size: 14px;
    line-height: 15px;
    background-color: $colorWhite;
    position: relative;
    z-index: 9;
    padding-left: 15px;
    padding-right: 15px;
    li{
      position: relative;
      padding-left: 15px;
      margin-left: 15px;
      &:first-child{
        padding-left: 0;
        margin-left: 0;
        &:before{
          display: none;
        }
      }
      &:before{
        position: absolute;
        content: "/";
        left: 0;
        top: 0;
        color: $colorTitle;
      }
      a{ 
        color: $colorTitle;
        font-weight: normal;
      }
    }
  }
}

