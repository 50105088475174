.plain-image-content{
  @include flex-left-center;
  background-color: $colorSpringWood;
  @include tablet-lg{
    height: 100%;
  }
  @include tablet-sm{
    display: block;
    border-radius: 30px;
    height: auto;
  }
  .image-holder{
    width: 50%;
    @include tablet-lg{
      height: 100%;
    }
    @include tablet-sm{
      width: 100%;
      height: auto;
    }
    img{
      width: 100%;
      border-radius: 50px 0 0 0;
      @include tablet-lg{
        height: 100%;
      }
      @include tablet-sm{
        border-radius: 30px 30px 0 0;
        height: auto;
      }
    }
  }
  .content-holder{
    width: 50%;
    padding: 30px;
    @include tablet-sm{
      width: 100%;
      padding: 15px;
    }
    .title{
      font-family: $content-font-family;
    }
  }

}