.slider-three-d{
    .slick-list {
    padding-left: 15%!important;
    padding-right: 15%!important;
  }
  
  .slick-dots {
    text-align: right;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .slick-track {
    max-width: 100%!important;
    transform: translate3d(0, 0, 0)!important;
    perspective: 100px;
  }
  
  .slick-slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
    width: 100%!important;
    transform: translate3d(130px, 0, 0);
    transition: transform 1s, opacity 1s;
    @include desktop-lg{
      transform: translate3d(35px, 0, 0);
    }
    @include tablet-lg{
      transform: translate3d(0px, 0px, -20px);
    }
    &.slick-current {
      &.slick-active {
        &.slick-center{
          .simple-card {
            .card-content {
              h4{
                @include show;
                transform: translateY(0);
              }
            }
          }
        }
      }
    }
  }
  
  .slick-snext,
  .slick-sprev,
  .slick-snext2,
  .slick-sprev2{
    display: block;
  }
  
  .slick-current {
    opacity: 1;
    position: relative;
    display: block;
    z-index: 2;
  }
  
  .slick-snext {
    opacity: 1;
    transform: translate3d(48%, 0, -32px);
    z-index: 1;
    perspective: 1000px;
    @include desktop-lg{
      transform: translate3d(30%, 0, -32px);
    }
    @include tablet-lg{
      transform: translate3d(28%, 0, -32px);
    }
  }
  .slick-snext2 {
    opacity: 1;
    transform: translate3d(92%, 0, -64px);
    z-index: 0;
    perspective: 1000px;
    @include desktop-lg{
      transform: translate3d(80%, 0, -64px);
    }
    @include desktop-lg{
      transform: translate3d(65%, 0, -64px);
    }
  }
  
  .slick-sprev {
    opacity: 1;
    transform: translate3d(-20%, 0, -32px);
    @include desktop-lg{
      transform: translate3d(-25%, 0, -32px);
    }
  }
  
  .slick-sprev2 {
    opacity: 1;
    transform: translate3d(-64%, 0, -64px);
  }
  .slick-btn{
    width: 40px;
    height: 40px;
  }
  .slick-btn{
    border-radius: 50%;
    z-index: 9;
    background-color: $colorWhite;
  }
  .slick-prev{
    margin-left: -345px;
    left: 50%;
    @include desktop-lg{
      margin-left: -380px;
    }
    @include tablet-lg{
      margin-left: -290px;
    }
    @include tablet-sm{
      margin-left: -220px;
    }
    @include mobile-lg{
      margin-left: -45px;
      bottom: -50px;
      top: auto;
      background-color: $colorGrey;
    }
    &:before{
      content: "\f053";
      font-family: 'Font Awesome 6 Pro';
      font-weight: 400;
      color: $colorTitle;
    }
  }
  .slick-next{
    margin-right: -345px;
    right: 50%;
    @include desktop-lg{
      margin-right: -315px;
    }
    @include tablet-lg{
      margin-right: -290px;
    }
    @include tablet-sm{
      margin-right: -220px;
    }
    @include mobile-lg{
      margin-right: -45px;
      bottom: -50px;
      top: auto;
      background-color: $colorGrey;
    }
    &:before{
      content: "\f054";
      font-family: 'Font Awesome 6 Pro';
      font-weight: 400;
      color: $colorTitle;
    }
  }
  // .test {
  //   display: block;
  //   width: 100%;
  //   height: 300px;
  //   background: #c00;
  //   box-shadow: inset 0px 0px 0px 3px #000
  // }
}  
