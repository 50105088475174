.fancybox__thumbs {
  .carousel__slide {
    .fancybox__thumb{
      &.is-nav-selected{
        &:after {
          content: "";
          border-width: 2px;
          border-color: $colorGoldDrop;
          border-radius: 0;
        }
      }
    }
  }
}